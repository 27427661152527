import axios from "axios";
import { store } from "@/store";
import { utils } from "@/api/http-common";
import { downloadError } from "@/utils/error";

export namespace statement {
	export async function uploadAbsaStatement(file: File) {
		let formData = new FormData();
		formData.append("file", file);
		return axios.post(utils.generateUrl(`api/admin/BankStatementEntry/Upload/Absa`, process.env.VUE_APP_SAFES_URL), formData, {
			headers: {
				...(await utils.generateHeader()),
				"Content-Type": "multipart/form-data"
			}
		});
	}

	export async function uploadNedbankStatement(file: File) {
		let formData = new FormData();
		formData.append("file", file);
		return axios.post(utils.generateUrl(`api/admin/BankStatementEntry/Upload/Nedbank`, process.env.VUE_APP_SAFES_URL), formData, {
			headers: {
				...(await utils.generateHeader()),
				"Content-Type": "multipart/form-data"
			}
		});
	}

	export async function uploadRecon(file: File) {
		let formData = new FormData();
		formData.append("file", file);
		return axios.post(utils.generateUrl(`api/admin/BankStatementEntry/Upload/Recon`, process.env.VUE_APP_SAFES_URL), formData, {
			headers: {
				...(await utils.generateHeader()),
				"Content-Type": "multipart/form-data"
			}
		});
	}

	export async function downloadRecon() {
		axios
			.get(utils.generateUrl(`api/admin/BankStatementEntry/Download/Recon`, process.env.VUE_APP_SAFES_URL), {
				headers: await utils.generateHeader(),
				responseType: "blob"
			})
			.then(response => {
				const data = window.URL.createObjectURL(response.data);
				const link = document.createElement("a");
				link.href = data;

				let disposition = response.headers["content-disposition"];
				let filename = "bankrecon.csv";
				if (disposition && disposition.indexOf("attachment") !== -1) {
					var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					var matches = filenameRegex.exec(disposition);
					if (matches != null && matches[1]) {
						filename = matches[1].replace(/['"]/g, "");
					}
				}

				link.download = filename;
				document.body.appendChild(link); // Required for FF
				link.click();
				store.dispatch("notifications/success", "File downloaded, please check your download folder.", { root: true });
			})
			.catch(err => {
				downloadError("Recon", err);
			});
	}

	export type format = "pdf" | "csv" | "xlsx";
	export type statementType = "orders" | "payments";

	const getDownloadUrl = (type: statementType, orgId: number) => {
		if (type === "payments") return `organisations/${orgId}/transactions/statements/download`;
		else return `orders/statement/download?supplier_org_id=${orgId}&salesOrder=true`;
	};

	export async function downloadStatement(from_date: string, to_date: string, format: format = "pdf", statementType: statementType, orgId: number) {
		axios
			.get(utils.generateUrl(getDownloadUrl(statementType, orgId)), {
				params: {
					format,
					from_date,
					to_date
				},
				headers: await utils.generateHeader(),
				responseType: "blob"
			})
			.then(response => {
				const data = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = data;
				const file = response.headers["content-disposition"].split("filename=")[1];
				const filename = file.replace('"', "").split(`.${format}`)[0];
				link.setAttribute("download", `${filename}.${format}`);
				link.click();
				store.dispatch("notifications/success", "File downloaded, please check your download folder.", { root: true });
			})
			.catch(error => {
				store.dispatch("notifications/error", "Failed to download file", { root: true });
			});
	}
}
