<template>
	<S2SForm title="Download Recon">
		<S2SCard>
			<v-flex v-if="loading" class="justify-center mt-10">
				<div class="text-center">
					<v-progress-circular indeterminate :size="80" :width="8" color="accent"></v-progress-circular>
					<p>The file will download automatically.</p>
				</div>
			</v-flex>
			<v-flex v-else-if="!loading" class="justify-center mt-10">
				<div class="text-center">
					<p>File downloaded, please check your download folder.</p>

					<p>
						If the file did not download.
						<v-btn color="accent" @click="retryDownload()">Try again</v-btn>
					</p>
				</div>
			</v-flex>
		</S2SCard>
	</S2SForm>
</template>

<script>
import Vue from "vue";
import { statement } from "../../api/statement-api";

export default Vue.extend({
	name: "DownloadRecon",

	data: function() {
		return {
			loading: true
		};
	},

	mounted() {
		statement.downloadRecon();

		setTimeout(() => {
			this.loading = false;
		}, 2000);
	},

	methods: {
		retryDownload() {
			statement.downloadRecon();
		}
	}
});
</script>
